
import { defineComponent, ref, watch, onMounted, PropType } from "vue";
import ApiService from "@/core/services/ApiService";
import { apiEndpoint } from "@/mixin/apiMixin";
import { useBus } from "@/bus.ts";

export default defineComponent({
  mixins: [apiEndpoint],
  name: "SurveyAnswerList",
  props: {
    data: {
      type: Object as PropType<{ id: number }>,
      required: true,
    },
  },
  setup(props) {
    const { bus } = useBus();

    const answerList = ref([]);

    const show = (id, mobile_no) => {
      const answerSheet = `/answer-sheet/${id}/${mobile_no}`;
      window.open(answerSheet, "_blank");
    };

    const loadAnswerList = async () => {
      try {
        const response = await ApiService.post(
          apiEndpoint.data().VUE_APP_SUERVEY_ANSWER_LIST,
          {
            survey_id: props.data.id,
          }
        );
        answerList.value = response.data.data;
      } catch (error) {
        console.error("Error fetching component options:", error);
      }
    };

    watch(
      () => props.data,
      (newData) => {
        if (props.data) {
          loadAnswerList();
        }
      }
    );

    // Load data on component mount
    onMounted(() => {
      if (props.data) {
        loadAnswerList();
      }
    });

    return { answerList, show };
  },
});
