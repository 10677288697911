
import { defineComponent, ref, onMounted, watch } from "vue";
import DynamicSurveyModal from "@/components/modals/forms/dynamic_survey/DynamicSurveyModal.vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import ApiService from "@/core/services/ApiService";
import { apiEndpoint } from "@/mixin/apiMixin";
import { ElNotification } from "element-plus";
import { useBus } from "@/bus.ts";
import { DrawerComponent } from "@/assets/ts/components";
import { useRouter } from "vue-router";
import SurveyAnswerList from "@/components/modals/forms/dynamic_survey/SurveyAnswerListModal.vue";
import CryptoJS from 'crypto-js';

export default defineComponent({
  mixins: [apiEndpoint],
  name: "DynamicSurveyList",
  components: {
    SurveyAnswerList,
    DynamicSurveyModal,
  },
  setup() {
    const router = useRouter();
    const { bus } = useBus();
    const editData = ref(null);
    const surveyList = ref([]);
    const trash = ref(false);
    const showAnswerData = ref<{ id: number } | null>(null);
    const secretKey = 'blims@997#123';


    const create = (data) => {
      editData.value = data ? data : null;
    };

    const edit = (data) => {
      editData.value = data;
    };

    const generateLink = (id) => {
      return window.location.origin + '/public-survey/' + encodeURIComponent(CryptoJS.AES.encrypt(id.toString(), secretKey).toString());
    };

    const copyUrl = (url) => {
      const input = document.createElement('input');
      input.style.position = 'fixed';
      input.style.opacity = '0';
      input.value = url;
      document.body.appendChild(input);
      input.select();
      input.setSelectionRange(0, 99999);
      document.execCommand('copy');
      document.body.removeChild(input);
      ElNotification({
        dangerouslyUseHTMLString: true,
        message: 'URL copied to clipboard!',
        type: "success",
      });
    };

    const loadAnswerList = (id) => {
      showAnswerData.value = {
        id,
      };
    };

    const destroy = async (id) => {
      Swal.fire({
        title: trash.value ? "Are you sure you want to restore it?" : "Are you sure you want to delete it?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            console.log(apiEndpoint.data().VUE_APP_OUTPUT_DELETE);
            const response = await ApiService.post(
                apiEndpoint.data().VUE_APP_SUERVEY_DELETE,
                {
                  id: id,
                }
            );

            if (response.data.status == "success") {
              ElNotification({
                dangerouslyUseHTMLString: true,
                message: response.data.data,
                type: "success",
              });
              loadData();
            } else {
              ElNotification({
                dangerouslyUseHTMLString: true,
                message: response.data.message,
                type: "error",
              });
            }
          } catch (error) {
            console.error("Error deleting output:", error);
            ElNotification({
              dangerouslyUseHTMLString: true,
              message: "Error deleting output",
              type: "error",
            });
          }
        }
      });
    };

    const questionList = (id) => {
      router.push({ name: 'question-list', params: { id: id } });
    };

    const loadData = async () => {
      try {
        const response = await ApiService.post(
            apiEndpoint.data().VUE_APP_SUERVEY_LIST,
            {
              trash: trash.value ? 1 : 0,
            }
        );
        surveyList.value = response.data.data;
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const loadTrash = () => {
      trash.value = !trash.value;
    };

    watch(trash, (newVal) => {
      loadData();
    });

    onMounted(async () => {
      DrawerComponent.bootstrap();
      DrawerComponent.updateAll();

      loadData();

      bus.on("loadData", async () => {
        loadData();
      });
    });

    return { editData, surveyList, trash, loadTrash, create, edit, loadAnswerList, destroy, questionList, showAnswerData, generateLink, copyUrl };
  },
});
